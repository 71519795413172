import { PatientParIdentifiantQueryQuery } from "@data/gql/graphql"
import { PatientParIdentifiantDocument, useQuery } from "@primary/data"
import { useEffect, useState } from "react"

export type PatientDuChannel =
  PatientParIdentifiantQueryQuery["patientParIdentifiant"]

export const usePatientDuChannel = (idProfilPatient: string | undefined) => {
  const [patient, setPatient] = useState<PatientDuChannel | null>(null)
  const [profilPrincipal, setProfilPrincipal] = useState<boolean | null>(null)
  const [nonOnboarde, setNonOnboarde] = useState<boolean | null>(null)

  const { data: donneesPatient } = useQuery(PatientParIdentifiantDocument, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { identifiant: idProfilPatient! },
    skip: !idProfilPatient,
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (donneesPatient?.patientParIdentifiant) {
      setPatient(donneesPatient.patientParIdentifiant)
      setProfilPrincipal(
        donneesPatient?.patientParIdentifiant?.compte?.profilPrincipal?.id ===
          donneesPatient?.patientParIdentifiant?.id,
      )
      setNonOnboarde(donneesPatient?.patientParIdentifiant.compte === null)
    } else {
      setPatient({ id: idProfilPatient } as PatientDuChannel)
      setProfilPrincipal(null)
      setNonOnboarde(null)
    }
  }, [donneesPatient, idProfilPatient])

  return { patient, profilPrincipal, nonOnboarde }
}
