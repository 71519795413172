import { Modal } from "@ds"
import { classNames, Document, ErrorMessage } from "@primary/design-system"
import { useTranslation } from "react-i18next"
import { FileWithPath, useDropzone } from "react-dropzone"
import { Field, Input, Label } from "@headlessui/react"
import { useEffect, useReducer } from "react"
import { capitalize } from "@utils/capitalize"
import { SelectCategorieDeDocument } from "./SelectCategorieDeDocument"
import { v4 as uuid } from "uuid"
import { useFileUploadMutation } from "./hooks/useFileUpload"
import { toast } from "@infra/toast/toast"
import {
  DemandeDImportDeDocumentDocument,
  DocumentsDocument,
  DocumentsQuery,
  useLazyQuery,
} from "@primary/data"

type Document = DocumentsQuery["documents"][number]

interface ImporterUnNouveauDocumentDialogProps {
  onClose: () => unknown
  identifiantDuProfilPatient: string
  onDocumentJoint: (document: Document) => unknown
}

type State = {
  nomDuFichier: string
  extension: string | null
  categorieId: string
  errors: {
    nomDuFichierVide: boolean
    categorieVide: boolean
    erreurDEnvoi: string | null
  }
  etat: "brouillon" | "envoiEnCours" | "envoiEchoue"
}

type Action =
  | {
      type: "MODIFIER_NOM_DU_FICHIER"
      nomDuFichier: string
    }
  | {
      type: "CHARGEMENT_FICHIER"
      fichier: FileWithPath
    }
  | {
      type: "CHOIX_CATEGORIE"
      categorieId: string
    }
  | {
      type: "VALIDER_FORMULAIRE"
    }
  | {
      type: "ERREUR_D_ENVOI"
      erreur: string
    }

const importerUnNouveauDocumentFormReducer = (
  state: State,
  action: Action,
): State => {
  switch (action.type) {
    case "MODIFIER_NOM_DU_FICHIER":
      return {
        ...state,
        nomDuFichier: action.nomDuFichier,
        errors: {
          ...state.errors,
          nomDuFichierVide: false,
        },
      }
    case "CHARGEMENT_FICHIER":
      return {
        ...state,
        nomDuFichier: recupererNomDuFichierSanitize(action.fichier.name),
        extension: action.fichier.name.split(".").pop() ?? null,
      }
    case "CHOIX_CATEGORIE":
      return {
        ...state,
        categorieId: action.categorieId,
        errors: {
          ...state.errors,
          categorieVide: false,
        },
      }
    case "VALIDER_FORMULAIRE":
      const errors = {
        erreurDEnvoi: null,
        nomDuFichierVide: state.nomDuFichier === "",
        categorieVide: state.categorieId === "",
      }
      return {
        ...state,
        errors,
        etat: Object.values(errors).some((error) => error)
          ? "brouillon"
          : "envoiEnCours",
      }
    case "ERREUR_D_ENVOI":
      return {
        ...state,
        etat: "envoiEchoue",
        errors: {
          ...state.errors,
          erreurDEnvoi: action.erreur,
        },
      }
  }
}

const recupererNomDuFichierSanitize = (nomDuFichier: string) => {
  const nomDuFichierSansExtension = nomDuFichier
    .split(".")
    .slice(0, -1)
    .join(".")
  return capitalize(nomDuFichierSansExtension.replace(/\s+/g, " ").trim())
}

export const ImporterUnNouveauDocumentDialog = ({
  onClose,
  identifiantDuProfilPatient,
  onDocumentJoint,
}: ImporterUnNouveauDocumentDialogProps) => {
  const { t } = useTranslation()
  const [{ nomDuFichier, categorieId, errors, etat, extension }, dispatch] =
    useReducer(importerUnNouveauDocumentFormReducer, {
      nomDuFichier: "",
      extension: null,
      categorieId: "",
      errors: {
        erreurDEnvoi: null,
        nomDuFichierVide: false,
        categorieVide: false,
      },
      etat: "brouillon",
    })
  const { upload } = useFileUploadMutation()
  const formulaireDesactive = !(etat === "brouillon" || etat === "envoiEchoue")
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "application/pdf": [],
      },
      multiple: false,
      disabled: formulaireDesactive,
    })

  const [demanderLImportDUnDocument] = useLazyQuery(
    DemandeDImportDeDocumentDocument,
    {
      fetchPolicy: "no-cache",
    },
  )
  const [recupererLesDocuments] = useLazyQuery(DocumentsDocument, {
    fetchPolicy: "network-only",
  })

  const fichier = acceptedFiles?.[0]
  const doitAfficherLaDropzone = !fichier || isDragActive
  useEffect(() => {
    if (fichier) {
      dispatch({
        type: "CHARGEMENT_FICHIER",
        fichier: fichier,
      })
    }
  }, [fichier])

  useEffect(() => {
    if (etat === "envoiEnCours") {
      if (extension === null) {
        dispatch({
          type: "ERREUR_D_ENVOI",
          erreur: t("messagerie.nousNePouvonsPasTraiterCeTypeDeFichier"),
        })
        return
      }

      const identifiantDuDocument = uuid()

      ;(async () => {
        const res = await demanderLImportDUnDocument({
          variables: {
            identifiantDuPatient: identifiantDuProfilPatient,
            nom: nomDuFichier,
            identifiantDeLaCategorie: categorieId,
            identifiantDuDocument: identifiantDuDocument,
            extension: extension,
          },
        })

        if (res.error) {
          dispatch({
            type: "ERREUR_D_ENVOI",
            erreur: t(
              "messagerie.uneErreurEstSurvenueLorsDeLaDemandeDImportDuDocument",
            ),
          })
          return
        }

        const adresse = res.data?.demandeDImportDeDocument.adresse
        if (adresse == null) {
          dispatch({
            type: "ERREUR_D_ENVOI",
            erreur: t(
              "messagerie.uneErreurEstSurvenueLorsDeLaDemandeDImportDuDocument",
            ),
          })
          return
        }
        await upload({
          presignedUploadUrl: adresse,
          file: fichier,
        }).catch(() => {
          dispatch({
            type: "ERREUR_D_ENVOI",
            erreur: t("messagerie.uneErreurEstSurvenueLorsDeLEnvoiDuDocument"),
          })
        })

        const essayerDeRecupererLeDocument = async () => {
          const data = await recupererLesDocuments({
            variables: { identifiantProfil: identifiantDuProfilPatient },
          })
          const document = data.data?.documents.find(
            (doc) => doc.id === identifiantDuDocument,
          )
          if (document) {
            onDocumentJoint(document)
            onClose()
          } else {
            throw new Error("Document introuvable")
          }
        }

        const recupererLeDocument = async (delay: number) => {
          await sleep(delay)
          return essayerDeRecupererLeDocument()
        }

        const doRecupererLeDocument = async () => {
          let tries = 0
          while (tries < 10) {
            try {
              await recupererLeDocument(tries * 1000)
              return
            } catch {
              tries++
            }
          }
          throw new Error("Impossible de récupérer le document")
        }

        doRecupererLeDocument().catch(() => {
          onClose()
          toast.error(
            t("messagerie.uneErreurEstSurvenueLorsDeLaRecuperationDuDocument"),
          )
        })
      })()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etat])

  return (
    <Modal
      cancelButton={false}
      open
      title={t("messagerie.importerUnDocument")}
      onClose={onClose}
      confirmButtonTitle={
        etat === "envoiEnCours"
          ? t("messagerie.envoiEnCours")
          : t("messagerie.joindreLeDocument")
      }
      loading={etat === "envoiEnCours"}
      disabled={fichier === undefined}
      onConfirm={() => {
        if (formulaireDesactive) {
          return
        }
        dispatch({ type: "VALIDER_FORMULAIRE" })
      }}
      size="small"
    >
      <input {...getInputProps()} />
      <div
        className={classNames(
          "flex w-full flex-col",
          doitAfficherLaDropzone &&
            "h-52 cursor-pointer items-center justify-center rounded-3xl border-4 border-dashed border-light-grey text-grey hover:bg-extra-light-grey",
        )}
        {...getRootProps()}
        onClick={doitAfficherLaDropzone ? getRootProps().onClick : undefined}
      >
        {doitAfficherLaDropzone ? (
          <p className="text-p-medium">
            {isDragActive
              ? t("messagerie.cestParfait")
              : t("messagerie.deposezUnFichierOuCliquez")}
          </p>
        ) : (
          <div className="flex gap-4">
            <div className="text-h1">
              <Document />
            </div>
            <div className="flex flex-1 flex-col gap-4">
              <Field className="flex flex-col gap-1">
                <Label className="text-left text-h5">
                  {t("messagerie.nomDuFichier")}
                </Label>
                <Input
                  disabled={formulaireDesactive}
                  autoFocus
                  name="nom_du_fichier"
                  type="text"
                  value={nomDuFichier}
                  onChange={(e) =>
                    dispatch({
                      type: "MODIFIER_NOM_DU_FICHIER",
                      nomDuFichier: e.target.value,
                    })
                  }
                  className={classNames(
                    "rounded-xl bg-extra-light-grey px-6 py-4 text-p-small text-dark-grey",
                    errors.nomDuFichierVide && "border border-error",
                  )}
                />
                <ErrorMessage
                  display={errors.nomDuFichierVide}
                  className="text-error"
                >
                  {t("messagerie.veuillezRenseignerLeNomDuFichier")}
                </ErrorMessage>
              </Field>
              <Field className="flex flex-col gap-1">
                <Label className="text-left text-h5">
                  {t("messagerie.categorie")}
                </Label>
                <SelectCategorieDeDocument
                  value={categorieId}
                  className={classNames(
                    errors.categorieVide && "border border-error",
                  )}
                  onChange={(categorieId) =>
                    dispatch({
                      type: "CHOIX_CATEGORIE",
                      categorieId: categorieId,
                    })
                  }
                  disabled={formulaireDesactive}
                />
                <ErrorMessage
                  display={errors.categorieVide}
                  className="text-error"
                >
                  {t("messagerie.veuillezRenseignerLaCategorie")}
                </ErrorMessage>
              </Field>
              <ErrorMessage
                display={!!errors.erreurDEnvoi}
                className="text-error"
              >
                {errors.erreurDEnvoi}
              </ErrorMessage>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

const sleep = (m: number): Promise<void> => new Promise((r) => setTimeout(r, m))
