import { BoutonMettreEnForme } from "@features/rendezVous/detailPreconsultation/components/BoutonMettreEnForme"
import {
  MettreEnFormeLaSyntheseContexteDocument,
  useLazyQuery,
} from "@primary/data"

export type BoutonMettreEnFormeSyntheseContexteProps = {
  markdown: string
  setMarkdown: (markdown: string) => void
}

export const BoutonMettreEnFormeSyntheseContexte = ({
  markdown,
  setMarkdown,
}: BoutonMettreEnFormeSyntheseContexteProps) => {
  const [mettreEnFormeQuery, { loading: isPending }] = useLazyQuery(
    MettreEnFormeLaSyntheseContexteDocument,
  )

  const mettreEnForme = async (): Promise<string> => {
    const response = await mettreEnFormeQuery({
      variables: { contenu: markdown },
    })
    if (response.error) {
      throw new Error(response.error.message)
    }
    return response.data?.miseEnFormeSyntheseContexte ?? markdown
  }

  return (
    <div data-testid={"bouton-synthese-contexte-mise-en-forme"}>
      <BoutonMettreEnForme
        contenu={markdown}
        setContenu={setMarkdown}
        mettreEnForme={mettreEnForme}
        miseEnFormeEnCours={isPending}
      />
    </div>
  )
}
