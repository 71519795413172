import {
  PatientParIdentifiantQueryQuery,
  StatutInscriptionProgrammePrimaryCare,
  StatutProchainDepistage,
} from "@data/gql/graphql"
import {
  Avatar,
  classNames,
  ArrowLeft,
  Doctolib,
  Dot,
  EyeFilled,
  PrimaryApp,
} from "@primary/design-system"
import { agePatient } from "@features/patient/agePatient"
import { contextePatientPanelRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { capitalize } from "@utils/capitalize"
import { ReactNode, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useTour } from "@reactour/tour"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useFlags } from "launchdarkly-react-client-sdk"
import { stepTourDepistagesPreconsultation } from "@features/tour/TourDepistages"
import { ProgrammePrimary } from "@features/rendezVous/detailPreconsultation/components/ProgrammePrimary"
import {
  DepistagePatientFragmentDoc,
  getFragmentData,
  DepistagesPatientParIdentifiantDocument,
  useQuery,
} from "@primary/data"

interface EntetePatientProps {
  patient: PatientParIdentifiantQueryQuery["patientParIdentifiant"]
  onBack?: () => void
  info?: ReactNode
  noBorder?: boolean
  showContexte?: boolean
}

export const EntetePatient = ({
  patient,
  onBack,
  info,
  noBorder,
  showContexte,
}: EntetePatientProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const patientPossedeLApplication = patient.compte
  const { data: patientParIdentifiantApollo } = useQuery(
    DepistagesPatientParIdentifiantDocument,
    {
      variables: { identifiant: patient.id },
    },
  )
  const depistagesAFaire =
    patientParIdentifiantApollo?.patientParIdentifiant.depistages.some((d) => {
      const depistage = getFragmentData(DepistagePatientFragmentDoc, d)
      return (
        depistage.statutProchainDepistage === StatutProchainDepistage.AFaire ||
        depistage.statutProchainDepistage === StatutProchainDepistage.EnRetard
      )
    })
  const { tourDepistagesPreconsultation, setPreferences } = usePreferences()
  const { lotPousserLesDepistagesDansDoctolib } = useFlags()

  const VoirLeProfil = () => {
    const { setIsOpen, setSteps } = useTour()

    useEffect(() => {
      if (
        lotPousserLesDepistagesDansDoctolib &&
        !tourDepistagesPreconsultation &&
        patient.depistages.length > 0
      ) {
        if (setSteps) {
          setSteps([stepTourDepistagesPreconsultation])
        }
        setIsOpen(true)
        setPreferences({ tourDepistagesPreconsultation: true })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <Dot />
        <div
          className="flex gap-2 hover:cursor-pointer"
          onClick={() => {
            setIsOpen(false)
            navigate({
              to: contextePatientPanelRoute.to,
              params: { identifiantProfil: patient.id },
            })
          }}
          id="voir-le-profil"
        >
          <div className="text-h5">{t("voirLeProfil")}</div>
          <EyeFilled color="bg-dark-plum" />
        </div>
      </>
    )
  }

  return (
    <div
      className={classNames(
        "flex items-center gap-4",
        noBorder ? "pt-6" : "border-b border-light-grey py-6",
      )}
      data-testid={"entete-patient"}
    >
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex items-center gap-4">
          <div
            className="cursor-pointer py-1 text-2xl text-black"
            onClick={onBack}
            data-testid="back-to-arrow"
          >
            <ArrowLeft />
          </div>
          <Avatar
            name={patient.prenom + " " + patient.nom}
            patientProgrammePrimary={
              patient.programme.statut ===
              StatutInscriptionProgrammePrimaryCare.Inscrit
            }
          />

          <span className="text-h2">
            {capitalize(patient?.prenom) + " " + patient.nom}
          </span>
          {info && info}
        </div>
        <div className="flex items-center gap-2">
          <span className="text-h5">{patient.sexe && t(patient.sexe)}</span>
          {patient.dateDeNaissance && (
            <>
              <Dot />
              <span className="text-h5">
                {patient.dateDeNaissance &&
                  agePatient(patient.dateDeNaissance, t)}
              </span>
            </>
          )}
          {patient.compte && patient.numeroDeTelephone && (
            <>
              <Dot />
              <span className="text-h5">{patient.numeroDeTelephone}</span>
            </>
          )}
          {patientPossedeLApplication && (
            <>
              <Dot />
              <PrimaryApp />
            </>
          )}
          {patient.lienDoctolib && patient.lienDoctolib.trim() !== "" && (
            <>
              <Dot />
              <a
                data-testid="lien-doctolib-patient"
                target="_blank"
                href={patient.lienDoctolib}
                rel="noreferrer"
              >
                <Doctolib className="text-h3" />
              </a>
            </>
          )}
          {depistagesAFaire && (
            <>
              <Dot />
              <div
                className={classNames(
                  "flex gap-2",
                  showContexte && "hover:cursor-pointer",
                )}
                onClick={() =>
                  showContexte &&
                  navigate({
                    to: contextePatientPanelRoute.to,
                    params: { identifiantProfil: patient.id },
                  })
                }
              >
                <div className="text-h5 text-error">
                  {t("depistagesAFaire")}
                </div>
              </div>
            </>
          )}
          {showContexte && <VoirLeProfil />}
        </div>
      </div>
      <ProgrammePrimary patient={patient} />
    </div>
  )
}
