import axios, { AxiosError } from "axios"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"

interface IArgs {
  presignedUploadUrl: string
  file: File
}

export const useFileUploadMutation = () => {
  const [progress, setProgress] = useState(0)

  const { mutate } = useMutation<void, AxiosError, IArgs>({
    mutationFn: (args: IArgs) =>
      axios.put(args.presignedUploadUrl, args.file, {
        onUploadProgress: (ev) =>
          setProgress(Math.round((ev.loaded * 100) / (ev.total ?? 1))),
      }),
  })

  const upload = (args: IArgs) =>
    new Promise((resolve, reject) => {
      mutate(args, {
        onSuccess: resolve,
        onError: reject,
      })
    })

  return { upload, progress }
}
