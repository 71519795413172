/* eslint-disable react-hooks/exhaustive-deps */
import { enregistrerSyntheseContexte } from "@data/patient/contextePatient/enregistrerSyntheseContexte"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"
import { useGQLMutation } from "@data/useGraphQL"
import { classNames } from "@primary/design-system"
import {
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
} from "@mdxeditor/editor"
import { useFlags } from "launchdarkly-react-client-sdk"
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { asyncScheduler, BehaviorSubject, skip, throttleTime } from "rxjs"
import { BoutonMettreEnFormeSyntheseContexteProps } from "./BoutonMettreEnFormeSyntheseContexte"
import { PatientParIdentifiantQueryQuery } from "@data/gql/graphql"
import { HabitudeDeVieSelector } from "./HabitudeDeVieSelector"
import { enregistrerHabitudeDeVie } from "@data/patient/contextePatient/enregistrerHabitudeDeVie"
import { JSX } from "react"

export interface ContextePatientMethods {
  setMarkdown: (markdown: string) => void
  getMarkdown: () => string
}

type ContextePatientProps = {
  identifiantProfil: string
  align?: "row" | "column"
  patient: PatientParIdentifiantQueryQuery["patientParIdentifiant"]
  boutonMettreEnForme?: (
    props: BoutonMettreEnFormeSyntheseContexteProps,
  ) => JSX.Element
  onChange?: () => void
}

export const ContextePatient = forwardRef<
  ContextePatientMethods,
  ContextePatientProps
>(
  (
    {
      identifiantProfil,
      align = "row",
      boutonMettreEnForme,
      patient,
      onChange,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const { modifierProfilPatient } = useFlags()

    // TODO(judithp): [Apollo] Use Apollo instead.
    const { mutate: enregistrerSynthese } = useGQLMutation(
      enregistrerSyntheseContexte,
      {
        invalidateQueryKeys: [
          [patientParIdentifiantQuery, { identifiant: identifiantProfil }],
        ],
        onSuccess: () => onChange?.(),
      },
    )
    // TODO(judithp): [Apollo] Use Apollo instead.
    const { mutate: enregistrerHabitude } = useGQLMutation(
      enregistrerHabitudeDeVie,
      {
        invalidateQueryKeys: [
          [patientParIdentifiantQuery, { identifiant: identifiantProfil }],
        ],
        onSuccess: () => onChange?.(),
      },
    )
    const contextePatient = patient?.contexte
    const initialMarkdown = contextePatient?.synthese?.contenu || ""

    const [markdown, setMarkdown] = useState<string>(initialMarkdown)
    const [markdownSubject] = useState<BehaviorSubject<string>>(
      new BehaviorSubject(initialMarkdown),
    )

    const editorRef = useRef<MDXEditorMethods>(null) //TODO(acuisinier): change to QuillJS instead of MDXEditor?

    useEffect(() => {
      if (!modifierProfilPatient) {
        return
      }
      const subscription = markdownSubject
        .pipe(skip(1))
        .pipe(
          throttleTime(1000, asyncScheduler, { leading: true, trailing: true }),
        )
        .subscribe((markdown) => {
          enregistrerSynthese({
            identifiantProfilPatient: identifiantProfil,
            contenu: markdown,
          })
        })
      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const doSetMarkdown = (markdown: string) => {
      // FIXME: not null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      editorRef.current!.setMarkdown(markdown)
      setMarkdown(markdown)
      markdownSubject.next(markdown)
    }
    useImperativeHandle(ref, () => ({
      setMarkdown: doSetMarkdown,
      getMarkdown: () => markdown,
    }))

    return (
      <div
        data-testid="contexte-patient"
        className={classNames(
          "items my-6 flex justify-between gap-8 rounded-xl p-4 py-4 shadow-very-light-shadow hover:shadow-hover-drop-shadow",
          align === "row" ? "flex-row" : "flex-col",
        )}
      >
        <div className="flex flex-1 flex-col">
          <div className="pl-2 text-h4">{"🏡 " + t("profilPatient")}</div>
          <div
            className={classNames(
              "group mb-4 flex-1",
              modifierProfilPatient ? "mb-4" : "my-4",
            )}
            onClick={() => editorRef.current?.focus()}
          >
            {modifierProfilPatient && (
              <div
                className={classNames(
                  "my-2 text-p-tiny italic text-grey transition-opacity group-has-[:focus-within]:opacity-100",
                  markdown.trim().length !== 0 && "opacity-0",
                )}
              >
                {t("questionsSyntheseContexte")}
              </div>
            )}
            <MDXEditor
              readOnly={modifierProfilPatient ? false : true}
              ref={editorRef}
              markdown={markdown}
              placeholder={
                modifierProfilPatient
                  ? null
                  : t("patientNAPasCompleteSonProfil")
              }
              onChange={(newMarkdown: string) => {
                if (newMarkdown !== markdownSubject.value) {
                  setMarkdown(newMarkdown)
                  markdownSubject.next(newMarkdown)
                }
              }}
              plugins={[
                listsPlugin(),
                headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
                markdownShortcutPlugin(),
              ]}
              iconComponentFor={(name) => <div>{name}</div>}
              contentEditableClassName="prose outline-none max-w-none !p-0 font-sans text-p-tiny"
              className={"flex-1 font-sans outline-none transition-opacity"}
            />
          </div>
          {modifierProfilPatient &&
            boutonMettreEnForme &&
            boutonMettreEnForme({
              markdown,
              setMarkdown: doSetMarkdown,
            })}
        </div>
        <div>
          <div className="text-h4">{t("habitudesDeVie")}</div>
          <div className="flex w-80 flex-col justify-between gap-2 pt-4">
            {modifierProfilPatient
              ? contextePatient?.habitudesDeVie.map((habitude) => (
                  <div
                    key={habitude.nom}
                    className="flex w-80 items-center justify-between gap-4"
                  >
                    <div className="text-p-tiny">
                      {habitude.picto + " " + habitude.nom}
                    </div>
                    <HabitudeDeVieSelector
                      selected={habitude.valeur}
                      choix={habitude.choix}
                      onSelect={(valeur: string) => {
                        enregistrerHabitude({
                          identifiantProfilPatient: identifiantProfil,
                          identifiantHabitudeDeVie: habitude.id,
                          valeur: valeur,
                        })
                      }}
                    />
                  </div>
                ))
              : contextePatient?.habitudesDeVie.map((habitude) => (
                  <div
                    key={habitude.nom}
                    className="flex w-80 items-center justify-between gap-4"
                  >
                    <div className="text-p-tiny">
                      {habitude.picto + " " + habitude.nom}
                    </div>
                    <div className="flex w-36 items-center rounded bg-extra-light-grey px-2 py-1 text-h6 text-dark-plum">
                      {habitude.valeur?.length > 0
                        ? habitude.valeur
                        : t("nonRenseigne")}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    )
  },
)
