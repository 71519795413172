import { MembreEquipeMedicaleConnecteDocument, useQuery } from "@primary/data"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { useAuth } from "@infra/auth"
import { usePreferences } from "@infra/preferences/usePreferences"
import { Avatar, ChevronDown, ChevronUp } from "@primary/design-system"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

export const UserProfile = () => {
  const { data } = useQuery(MembreEquipeMedicaleConnecteDocument)
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { sideBarOuverte: isSideBarOuverte, setPreferences } = usePreferences()

  const utilisateur = data?.membreEquipeMedicaleConnecte
  const nomUtilisateur = utilisateur?.prenom + " " + utilisateur?.nom

  const profilePic = (
    <div data-testid="user-profile-pic">
      {utilisateur?.photo ? (
        <div className="h-8 w-8 cursor-pointer">
          <img
            className="rounded border-white"
            src={utilisateur.photo}
            alt={`photo-${nomUtilisateur}`}
          />
        </div>
      ) : (
        <div className="cursor-pointer">
          <Avatar name={nomUtilisateur} />
        </div>
      )}
    </div>
  )

  if (!utilisateur) {
    return null
  }

  return (
    <div className="mb-6 mr-6 flex flex-col">
      {isSideBarOuverte ? (
        <Menu>
          <MenuButton as={Fragment}>
            {({ open }) => (
              <button className="flex flex-1 items-center justify-between gap-2 pl-8 pt-2">
                {profilePic}
                <div className="truncate text-p-small">{nomUtilisateur}</div>
                {open ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronUp className="h-4 w-4" />
                )}
              </button>
            )}
          </MenuButton>
          <MenuItems
            anchor="top start"
            className="mx-4 min-w-52 rounded-xl bg-white p-3 shadow-light-shadow"
          >
            <MenuItem>
              <div
                className="block cursor-pointer rounded-lg p-2 text-p-small hover:bg-extra-light-grey"
                onClick={() => logout()}
              >
                {t("meDeconnecter")}
              </div>
            </MenuItem>
            {import.meta.env.MODE === "staging" && (
              <MenuItem>
                <div
                  className="block cursor-pointer rounded-lg p-2 text-p-small hover:bg-extra-light-grey"
                  onClick={() => {
                    throw new Error("Crash test")
                  }}
                >
                  {t("crash")}
                </div>
              </MenuItem>
            )}
          </MenuItems>
        </Menu>
      ) : (
        <div
          className="pl-4"
          onClick={() => setPreferences({ sideBarOuverte: true })}
        >
          {profilePic}
        </div>
      )}
    </div>
  )
}
