import { Modal } from "@ds"
import {
  PatientSelectionne,
  PatientTrouve,
} from "@features/patient/pages/PatientTrouve"
import { SearchBar } from "@features/patient/pages/SearchBar"
import { conversationRoute } from "@infra/navigation/router"
import {
  CreerUneConversationAvecLePatientDocument,
  PatientsDocument,
  useMutation,
  useQuery,
} from "@primary/data"
import { MagnifyingGlass, PlainButton } from "@primary/design-system"
import { useNavigate } from "@tanstack/react-router"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface CreerUneConversationAvecLePatientParams {
  identifiantProfil: string
  identifiantDuCabinet: string
}

export const useNouveauMessage = () => {
  const [modaleAffichee, setModaleAffichee] = useState(false)
  const { t } = useTranslation()

  const ModaleNouveauMessage = () => {
    const [recherche, setRecherche] = useState("")
    const [patientSelectionne, setPatientSelectionne] =
      useState<PatientSelectionne | null>()
    const navigate = useNavigate()
    const { data } = useQuery(PatientsDocument, {
      variables: { recherche },
      skip: recherche.length <= 2,
    })

    const [mutate] = useMutation(CreerUneConversationAvecLePatientDocument)

    const creerUneConversation = async (
      params: CreerUneConversationAvecLePatientParams,
    ) => {
      const response = await mutate({
        variables: {
          identifiantProfil: params.identifiantProfil,
          identifiantDuCabinet: params.identifiantDuCabinet,
        },
      })
      try {
        navigate({
          to: conversationRoute.to,
          params: {
            conversationId:
              response.data?.creerUneConversationAvecLePatient
                .identifiantStreamChat,
          },
        })
      } catch (error) {
        // TODO: handle error
        console.error("Impossible de rejoindre la conversation", error)
      }
    }

    const onClose = () => {
      setRecherche("")
      setPatientSelectionne(null)
      setModaleAffichee(false)
    }

    useEffect(() => {
      setPatientSelectionne(null)
    }, [data?.patients, recherche])

    return (
      <Modal
        open={modaleAffichee}
        title={t("messagerie.creerUneConversation")}
        onClose={onClose}
        onConfirm={() => {
          creerUneConversation({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            identifiantProfil: patientSelectionne!.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            identifiantDuCabinet: patientSelectionne!.cabinetPrincipal.id,
          })
          onClose()
        }}
        dataTestId="modale-nouveau-message"
        cancelButton={false}
        confirmButtonTitle={t("messagerie.creerLaConversation")}
        disabled={!patientSelectionne}
        size="medium"
      >
        <div className="flex w-full flex-col">
          <SearchBar
            icon={
              <MagnifyingGlass className="stroke-dark-plum text-xl text-light-grey group-hover:stroke-white" />
            }
            onType={setRecherche}
          />
          {data?.patients && data?.patients.length > 0 && (
            <div className="flex max-h-52 flex-col overflow-y-scroll border-t border-t-light-grey pt-4">
              {data?.patients?.map((patient) => (
                <PatientTrouve
                  key={patient.id}
                  patient={patient}
                  selectPatient={setPatientSelectionne}
                  selected={patient.id === patientSelectionne?.id}
                  disabled={patient.numeroDeTelephone === null}
                  disabledReason={t("messagerie.patientSansNumeroDeTelephone")}
                />
              ))}
            </div>
          )}
        </div>
      </Modal>
    )
  }

  const BoutonNouveauMessage = () => {
    return (
      <PlainButton
        title={t("messagerie.nouveauMessage")}
        type="outlined"
        data-testid="nouveau-message"
        onClick={() => setModaleAffichee(true)}
      />
    )
  }

  return {
    BoutonNouveauMessage,
    ModaleNouveauMessage,
  }
}
